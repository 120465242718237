// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-create-pages-templates-article-post-tsx": () => import("./../../src/createPages/templates/article-post.tsx" /* webpackChunkName: "component---src-create-pages-templates-article-post-tsx" */),
  "component---src-create-pages-templates-blog-list-tsx": () => import("./../../src/createPages/templates/blog-list.tsx" /* webpackChunkName: "component---src-create-pages-templates-blog-list-tsx" */),
  "component---src-create-pages-templates-blog-post-tsx": () => import("./../../src/createPages/templates/blog-post.tsx" /* webpackChunkName: "component---src-create-pages-templates-blog-post-tsx" */),
  "component---src-create-pages-templates-homepage-tsx": () => import("./../../src/createPages/templates/homepage.tsx" /* webpackChunkName: "component---src-create-pages-templates-homepage-tsx" */),
  "component---src-create-pages-templates-tag-tsx": () => import("./../../src/createPages/templates/tag.tsx" /* webpackChunkName: "component---src-create-pages-templates-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../src/pages/contact-success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-mainpage-tsx": () => import("./../../src/pages/mainpage.tsx" /* webpackChunkName: "component---src-pages-mainpage-tsx" */)
}

