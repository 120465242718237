module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oakstone Trading","short_name":"Oakstone Trading","description":"Trading company","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","lang":"en","start_url":"/","include_favicon":true,"icon":"static/logo.png","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"3ec22b99bf56b398db51b066ea050d36"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.oakstonetrading.com/","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
